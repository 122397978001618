import { useState, useEffect } from 'react';
import map from './assets/small.png';
export function Modal(){
  const [displayPopUp, setDisplayPopUp] = useState(true);
  const closePopup = (e) =>{
    localStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setDisplayPopUp(false);
  }
  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = displayPopUp ? "hidden" : "auto";
    }
  }, [displayPopUp]);
  useEffect(() => {
    // getting value of "seenPopUp" key from localStorage
    let returningUser = localStorage.getItem("seenPopUp");
    // if it's not there, for a new user, it will be null
    // if it's there it will be boolean true
    // setting the opposite to state, false for returning user, true for a new user
    setDisplayPopUp(!returningUser);
  }, []);

  if (displayPopUp) {
    return (
      <div className='modal-overlay animate-fade animate-duration-700 fixed top-0 left-0 right-0 bottom-0 backdrop-blur-md bg-opacity-90 z-40 flex justify-center items-center'>
        <div className='modal-body animate-jump animated-fade   animate-duration-500 bg-white w-[90%] lg:w-[40%] lg:h-fit p-4 xl:p-[3%] rounded-[20px] flex flex-col items-center justify-center shadow-custom1'>



          <h2 className='text-center h2'>Olá, bem vindo.</h2>


          <h3 className='text-center h3 mb-2'>Ao novo website da AMDSC da Barreira.</h3>


          <p className='text-sm p-4 leading-loose pb-1'>Os conteúdos presentes neste site são provisórios e estão sujeitos a alterações sem aviso prévio. As informações fornecidas podem não refletir a realidade atual da organização até que este aviso seja removido. Recomendamos consultar regularmente este site para obter as informações mais recentes e precisas.</p>

          <button className='mt-8 btn rounded-full btn-lg btn-accent mx-auto xl:mx-0 self-center' onClick={closePopup}>
              Navegar <span className='hidden md:flex'>&nbsp;agora</span>
          </button>


        </div>
      </div>
    )
  }
}