import { useState } from 'react';
import Rel_AMDSCB_2020 from './../docs/Rel_AMDSCB_2020.PDF';
import Rel_AMDSCB_2021 from './../docs/Rel_AMDSCB_2021.PDF';
import Rel_AMDSCB_2022 from './../docs/Rel_AMDSCB_2022.PDF';
import Rel_AMDSCB_2023 from './../docs/Rel_AMDSCB_2023.PDF';

const Documents = () => {

  const HandleTab = (tab) => {
    setActiveTab(tab);
  }

  const TabLink = (props) => {
    let baseClass = 'w-full lg:w-normal box-border btn p-4 bg-white font-bold text-primary hover:text-accent transition-all';
    let activeClass = 'w-full lg:w-normal box-border btn p-4 bg-accent-secondary font-bold text-white transition-all';
    return (
      <button className={props.active == true ? (activeClass) : (baseClass)}>
        {props.title}
      </button>
    )
  }

  const FileEntry = (props) => {
    var fileico;
    if (props.filetype == "pdf") {
      fileico = <i className="ri-file-pdf-2-line text-[16px] md:text-[32px] text-rose-400"></i>
    }
    else if (props.filetype == "word") {
      fileico = <i className="ri-file-word-2-line text-[16px] md:text-[32px] text-sky-500"></i>
    }
    else {
      fileico = <i className="ri-file-2-line text-[16px] md:text-[32px] text-gray-400"></i>
    }
    return (
      <a
        className='bg-white hover:bg-gray-50 hover:shadow-custom2 transition-all m-2 p-3 px-2 md:px-6   flex items-center justify-between text-gray-300 hover:text-accent-secondary cursor-pointer'
        href={props.file}
      >
        <div className='flex gap-5 items-center'>
          {fileico}
          <p className='hidden md:flex font-semibold text-sm lg:text-md text-primary'>{props.title}</p>
          <p className='flex md:hidden font-semibold text-sm lg:text-md text-primary'>{props.titleShort}</p>
        </div>
        <i className="hidden md:flex ri-download-2-fill  text-[24px]"></i>
      </a>
    )
  }

  const [activeTab, setActiveTab] = useState("2023");

  return (
    <section name="documentos" className='bg-primary w-full p-8'>
      <div className='container mx-auto p-8 px-0 '>
        <div className=' rounded-[20px]  flex flex-col justify-center xl:justify-start items-center xl:items-start'>
          <h2 className='text-white h2'>Documentos</h2>
          <p>
            Nesta secção apresentamos os nossos documentos públicos. Caso deseje acesso a mais informações, envie-nos um e-mail para ter acesso aos restantes conteúdos:
          </p>

          <div className='w-full my-6'>
            <div id="Tablist" className="flex flex-wrap mt-8 justify-start">

              <div onClick={() => HandleTab("2019")} className='flex-grow lg:flex-grow-0 '>
                <TabLink title="2019" active={activeTab == "2019" ? (true) : (false)} />
              </div>

              <div onClick={() => HandleTab("2020")} className='flex-grow lg:flex-grow-0 '>
                <TabLink title="2020" active={activeTab == "2020" ? (true) : (false)} />
              </div>

              <div onClick={() => HandleTab("2021")} className='flex-grow lg:flex-grow-0 '>
                <TabLink title="2021" active={activeTab == "2021" ? (true) : (false)} />
              </div>

              <div onClick={() => HandleTab("2022")} className='flex-grow lg:flex-grow-0 '>
                <TabLink title="2022" active={activeTab == "2022" ? (true) : (false)} />
              </div>

              <div onClick={() => HandleTab("2023")} className='flex-grow lg:flex-grow-0 '>
                <TabLink title="2023" active={activeTab == "2023" ? (true) : (false)} />
              </div>


            </div>


            <div className='mb-8 bg-grey w-full block min-h-[300px]'>

              <div className={activeTab == "2019" ? ("px-2 md:px-8 py-8 w-full flex flex-col   bg-grey border-t") : ("hidden")}>

                <div className='select-none bg-white transition-all m-2 p-3 px-2 md:px-6   flex items-center justify-between text-gray-300'>
                  <div className='flex gap-5 items-center'>
                    <i className="ri-rounded-corner text-[16px] md:text-[32px] text-primary"></i>
                    <p className='hidden md:flex font-semibold text-sm lg:text-md text-gray-400'>Ainda não foram adicionados ficheiros a esta secção</p>
                    <p className='flex md:hidden font-semibold text-sm lg:text-md text-gray-400'>Nenhum ficheiro</p>
                  </div>
                </div>
              </div>

              <div className={activeTab == "2020" ? ("px-2 md:px-8 py-8 w-full flex flex-col   bg-grey border-t") : ("hidden")}>
                <FileEntry
                  title="Balancete Analítico 2020"
                  titleShort="Bal. Analitico"
                  file={Rel_AMDSCB_2020}
                  filetype="pdf"
                />

              </div>
              <div className={activeTab == "2021" ? ("px-2 md:px-8 py-8 w-full flex flex-col   bg-grey border-t") : ("hidden")}>
                <FileEntry
                  title="Balancete Analítico 2021"
                  titleShort="Bal. Analitico"
                  file={Rel_AMDSCB_2021}
                  filetype="pdf"
                />

              </div>
              <div className={activeTab == "2022" ? ("px-2 md:px-8 py-8 w-full flex flex-col   bg-grey border-t") : ("hidden")}>
                <FileEntry
                  title="Balancete Analítico 2022"
                  titleShort="Bal. Analitico"
                  file={Rel_AMDSCB_2022}
                  filetype="pdf"
                />

              </div>
              <div className={activeTab == "2023" ? ("px-2 md:px-8 py-8 w-full flex flex-col   bg-grey border-t") : ("hidden")}>
                <FileEntry
                  title="Balancete Analítico 2023"
                  titleShort="Bal. Analitico"
                  file={Rel_AMDSCB_2023}
                  filetype="pdf"
                />

              </div>
            </div>



          </div>
        </div>
      </div>
    </section>
  )
}
export { Documents }