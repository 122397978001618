import {Fragment, useState} from 'react';
import logoimg from './assets/logo_small.jpg';
import { Link } from 'react-scroll'
import clbadge from './assets/clbadge.png';

var desktop_navoffset = 64;

const NavLinks = (props) =>{
  var ulClass, liClass, wrapperClass;
  var offset;
  var liClassBase = "text-secondary hover:text-accent transition-all duration-300 cursor-pointer"
  if (props.isMobile==true){
    ulClass = "flex flex-col gap-y-5";
    liClass = liClassBase
    wrapperClass = "";
    offset = 0;
  }
  else{
    ulClass = "select-none flex w-full gap-x-5 h-full";
    liClass = "text-secondary hover:text-accent transition-all duration-300 border-r pr-4 cursor-pointer";
    wrapperClass = "h-full flex items-center";
    offset = -desktop_navoffset;
  }
  var scrollDuration = 1000;
  return(
    <ul className={ulClass}>
      <li className={wrapperClass}>
        <Link to="top" className={liClass}
          smooth='easeInOutQuad'
          duration={scrollDuration}
        >
          Início
        </Link>
      </li>

      

      <li className={wrapperClass}>
        <Link to="organizacao" className={liClass}
          smooth='easeInOutQuad'
          duration={scrollDuration}
          offset={offset}
        >
          Organização
        </Link>
      </li>

      <li className={wrapperClass}>
        <Link to="noticias" className={liClass}
          smooth='easeInOutQuad'
          duration={scrollDuration}
          offset={offset}
        >
          Noticias
        </Link>
      </li>

      <li className={wrapperClass}>
        <Link to="documentos" className={liClassBase} 
          smooth='easeInOutQuad'
          duration={scrollDuration}
          offset={offset}
        >
          Documentos
        </Link>
      </li>

      
      
      {
      props.isMobile == true ? 
      (<li>
          <Link to="contactos" className={liClass}
            smooth='easeInOutQuad'
            duration={scrollDuration}
            offset={offset}
          >
          Contactos
        </Link>
      </li>)
      :
      (<></>)
      }
      
    </ul>
    


  )
}
const NavBar = () =>{
  const [menuOpen, setMenuOpen] = useState(true);

  var menuClass = "bg-white fixed w-[300px] top-0 h-screen shadow-2xl lg:hidden transition-all duration-300 z-20";
  var arrowClass = "text-[32px] text-white"
  const menuOpenClass = " -right-[300px]";
  const menuClosedClass = " right-0";
  const arrowLeftClass = " ri-arrow-left-s-line";
  const arrowRightClass = " ri-arrow-right-s-line";

  if(menuOpen==true){
    menuClass= menuClass + menuOpenClass;
    arrowClass = arrowClass + arrowLeftClass;
  }else{
    menuClass = menuClass + menuClosedClass;
    arrowClass = arrowClass + arrowRightClass;
  }
  return(
    <Fragment>
      <header name="top" className='py-8 lg:pt-6 lg:pb-6 bg-white box-border'>
        <div className='w-full lg:relative flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0'>
        
          {/* Image */}
          <div className="lg:pl-[10%] flex justify-center lg:justify-normal">
            <img className="h-16"src={logoimg}/>
          </div>

          {/* Quick Links */}
          <div className='lg:pr-[10%] flex flex-col gap-y-4 lg:flex-row lg:gap-x-10 lg:gap-y-0'>
            
            <div className='flex justify-center items-center gap-x-2 lg:justify-normal'>
              <i className="ri-map-pin-2-fill text-2xl text-accent">
              </i>
              Lugar da Chandria, Barreira
            </div>
            
            <div className='flex justify-center items-center gap-x-2 lg:justify-normal'>
              <i className="ri-phone-fill text-2xl text-accent">
              </i>
              <div className='text-secondary'>
                <span className="opacity-60">(+351)</span> 279 859 498
              </div>
            </div>



            

          </div>
        </div>
      </header>
      {/* Mobile Navigation Menu */}
      <nav className={menuClass}>

        {/* Button */}
        <div className='bg-primary w-16 h-16 relative right-[64px] top-8 flex justify-center items-center rounded-tl-lg rounded-bl-lg cursor-pointer transition-all'
          onClick={() => setMenuOpen(!menuOpen)}>
          <i className={arrowClass}></i>
        </div>

        {/* Links */}
        <div className="px-12 flex flex-col gap-y-12 h-full">
          {/* Logo */}
          <a href="#">
            <div className="flex justify-center lg:justify-normal">
              <img className="h-14" src={logoimg} />
            </div>
          </a>
          {/*Links*/}
          <NavLinks isMobile={true} />
        </div>
      </nav>
      {/* Desktop Navigation Menu */}
      <nav className='bg-white sticky w-full top-0 h-16 hidden lg:flex lg:items-center lg:justify-between lg:px-[10%] shadow-custom1 z-30 border border-t-0 border-x-0 border-b-grey'>

        {/*Links*/}
        <NavLinks isMobile={false} />
        <div className='text-secondary select-none cursor-pointer hover:text-accent transition-all duration-300 border-l pl-9'>
          <Link to="contactos"
            smooth='easeInOutQuad'
            duration={1000}
            offset={-desktop_navoffset}
          >
            Contactos
          </Link>
        </div>
      </nav>
    </Fragment>
  )
}
const Footer = () =>{
  return(
    <footer>
      <div className="bg-primary py-4 min-h-[450px]">
        <div className="container w-full px-4 mx-auto">
          <div className=" flex flex-col lg:flex-row w-full justify-start">

            <div className="px-4 lg:px-0 lg:pr-4 my-4 w-full sm:w-auto">
              <div>
                <h2 className="text-white text-2xl pb-4 mb-4 border-b-4 border-accent-secondary">Navegação</h2>
                <div className='text-sm'>
                  <NavLinks isMobile={true} />
                </div>
              </div>
            </div>
            
            <div className="px-4 my-4 w-full sm:w-auto">
              <div>
                <h2 className="text-white text-2xl pb-4 mb-4 border-b-4 border-accent-secondary">Sobre</h2>
                <p className='text-secondary text-sm mb-1'>
                  [🇵🇹] Website para a Associação de Melhoramentos e Desenvolvimento Social e Cultural da Barreira (AMDSCB).
                </p>
                <p className='text-secondary text-sm'>
                  Este website encontra-se em atualização de conteúdos e poderá ser modificado no futuro.
                </p>
                <hr className='opacity-25 my-3'></hr>
                <p className='text-secondary text-sm mb-1'>
                  [EN] Website for the Barreira Association for Improvements and Social/Cultural Development (AMDSCB).
                </p>
                <p className='text-secondary text-sm'>
                  This website is still under content updates and will be subject to change in the future.
                </p>
              </div>
            </div>


            <div className="px-4 lg:px-0 lg:pl-4 my-4 w-full sm:w-auto">
              <div>
                <h2 className="text-white text-2xl pb-4 mb-4 border-b-4 border-accent-secondary">Informação Técnica</h2>
                <div className='w-[100px] overflow-hidden rounded-md mb-1'>
                  <img src={clbadge} />
                </div>
                <p className='text-secondary text-sm mb-1'>
                  amdscbarreira.pt registered at <a className="underline" href="https://www.site.pt/">Site.pt</a> and hosted by <a className="underline" href="https://www.cloudflare.com/">Cloudflare</a>
                </p>
                <hr className='opacity-25 my-3'></hr>
                <p className='text-secondary text-sm mb-1'>
                  Webstack - ReactJS + TailwindCSS @ AWS Amplify
                </p>
                <p className='text-secondary text-sm mb-1'>
                  Icons via <a className="underline" href="https://remixicon.com/">RemixIcon</a> | Remix Design © 2018-2023
                </p>
                <p className='text-secondary text-sm mb-1'>
                  Images by <a className="underline" href="https://www.freepik.com/author/stories/">Storyset</a> @ Freepik
                </p>

                <div className='flex gap-2 my-2 text-[24px]'>
                  <i className="ri-javascript-fill"></i>
                  <i className="ri-reactjs-fill "></i>
                  <i className="ri-amazon-line"></i>
                  <i className="ri-remixicon-fill "></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#1c2122] py-2 text-sm font-light">
        <div className="container mx-auto px-4">
          <div className="-mx-4 flex flex-wrap gap-7 justify-start text-secondary font-light">
            <div className="">
              AMDSCB © 2023
            </div>
            <div className="">
              webdev J.md
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export { NavBar, Footer }