import { Link } from 'react-scroll'
import heroimg from './assets/hero.png';
const Hero = () =>{
  
  return(
    <section name="hero" className='hero bg-grey py-12 xl:pt-6 xl:pb-6 overflow-hidden'>
      <div className='container mx-auto h-full'>
        <div className='flex flex-col xl:flex-row items-center justify-center h-full md:h-[40vh] lg:h-[50vh] xl:h-[75%] xl:justify-between'>


          <div className='hero__text xl:w-[48%] text-center xl:text-left'>
            <h1 className="h1 mb-6">A criar razões para vir e ficar</h1>
            <p className='mb-[42px] md:max-w-xl'>
              A apoiar a comunidade da Barreira desde 1998, com programas de desenvolvimento cultural, dinamização de atividades de ocupação de tempos e apoio à comunidade sénior.
            </p>
            <button className='btn rounded-full btn-lg btn-accent mx-auto xl:mx-0'>
              <Link to="organizacao"
                smooth='easeInOutQuad'
                duration={1000}
              >
                Saiba Mais
              </Link>
            </button>
          </div>


          <div className="hero__img hidden xl:flex xl:max-w-[637px]">
            <img src={heroimg} alt=""/>
          </div>



        </div>
      </div>
    </section>
  )


}

export {Hero};