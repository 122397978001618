import {NavBar, Footer} from './Nav.js';
import {Modal} from './Modal.js'
import {Hero} from './Hero.js';
import {Stats, Activities, Organization, Contacts, News} from './Sections.js';
import {Documents} from './pages/Documents.jsx';
import 'remixicon/fonts/remixicon.css'

const App = () => {
  return (
    <div className="App scroll-smooth">
        <h1 className='absolute z-50 opacity-0 select-none'>
          Associação de Melhoramentos e Desenvolvimento Social e Cultural da Barreira
        </h1>
        <Modal />
        <NavBar/>
        <Hero/>
        <Stats />
        <Activities />
        <Organization />
        <News />
        <Documents />
        <Contacts />
        <Footer />
    </div>
  );
}
export { App };
