import { useState, useRef } from 'react';
import Collapsible from 'react-collapsible';
import CountUp from 'react-countup';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import gra from './assets/unc.jpg'; 
import unc from './assets/gra.JPG'; 
import jos from './assets/jos.JPG'; 

import news1 from './assets/news1.jpg';
import news2 from './assets/news2.jpg';
import news3 from './assets/news3.jpg';
import news4 from './assets/news4.jpg';

import test from './assets/test.png';
import map from './assets/map.PNG';

import Rel_AMDSCB_2020 from './docs/Rel_AMDSCB_2020.PDF';
import Rel_AMDSCB_2021 from './docs/Rel_AMDSCB_2021.PDF';
import Rel_AMDSCB_2022 from './docs/Rel_AMDSCB_2022.PDF';
import Rel_AMDSCB_2023 from './docs/Rel_AMDSCB_2023.PDF';

var news = [
  {
    headline:"O novo site AMDSCB",
    image:news4,
    date:"30 Dezembro 23",
    description: "O novo website da associação está agora ao vivo, com todas as info. públicas da associação e mais informação, como notícias e afins."
  },
  {
    headline: "XIV Expo Mêda 23",
    image: news1,
    date: "6 Dezembro 23",
    description: "Estivemos na ExpoMêda, a fim de divulgar produtos regionais feitos na Barreira, e as atividades que se avizinham. Em destaque, o pão caseiro da Dna. XYZ."
  },
  {
    headline: "Mostra Regional",
    image: news2,
    date: "6 Outubro 23",
    description: "Fomos até ao Castelo de Marialva divulgar os nossos produtos regionais, e dar a conhecer a AMDSCB."
  },
  {
    headline: "Passeio ao Côa",
    image: news3,
    date: "6 Agosto 23",
    description: "Levamos membros e familiares aos passadiços do Côa para dinamizar a atividade física e dar um momento de convívio aos que puderam vir."
  },
];

///////////////////////////////////////////////////////////////////////////////////////////////////
const Stats = () =>{
  const Stat = (props) =>{
    return(
      <div className='stats__item flex-1 lg:border-r flex flex-col items-center'>
        <div className='text-4xl xl_text-[64px] font-semibold text-accent-secondary xl:mb-2'>
          <CountUp end={props.number} scrollSpyOnce />
        </div>
        <div>{props.text}</div>
      </div>
    )
  }
  return(
    <section name="estatisticas" className='section'>
      <div className='container mx-auto'>
        
        {/* Wrapper */}
        <div className='flex flex-col lg:flex-row gap-y-12  justify-between'>

          <Stat number={53} text="Pessoas assistidas"/> 
          <Stat number={73} text="Atividades nos últimos 5 anos" /> 
          <Stat number={14} text="Parcerias" /> 

        </div>


      </div>
    </section>
  )
}
////////////////////////////////////////////////////////////////////////////////////////////////////
const Activities = () =>{
  return(
    <section name="atividades" className='mb-[128px]'>
      <div className='container mx-auto'>
        <div className='py-8 lg:py-0 bg-accent-secondary max-w-[1466px] mx-4 xl:mx-auto rounded-[20px] xl:pt-[70px] px-6 xl:px-8 relative min-h-[368px] flex justify-center xl:justify-start items-center xl:items-start -z-10'>
          <div className='services__top flex items-center flex-col xl:flex-row xl:mb-[60px]'>
            <h2 className='h2 text-white flex-1 mb-4 xl:mb-0 text-center  xl:text-left'>
              Acompanhe as nossas atividades
            </h2>
            <p className='text-white flex-1 text-center xl:text-left max-w-2xl xl:max-w-none'>
              Proporcionamos um leque de apoios e atividades  para a comunidade da Barreira, procurando  incentivar a ocupação dos indivíduos, dinamizar  atividades lúdicas e sociais e proporcionar  apoios de saúde e das necessidades básicas dos residentes.
            </p>
          </div>  
        </div>   
      </div>
      <div className='container mx-auto mt-8 xl:-mt-[144px]'>
        <div className='grid xl:grid-cols-4 gap-5 px-8'>

          <div className='services__item bg-white p-[30px] rounded-[10px] shadow-custom2 min-h-[288px] flex flex-col items-center text-center justify-center xl:justify-start'>
            <div>
              <i className='ri-home-4-fill text-[50px] text-accent' />
            </div>
            <h3 className='h3 mt-[10px] mb-[10px]'>Apoio ao Domicílio e Centro de Dia</h3>
            <p className='font-light leading-normal max-w-[300px]'>Fornecer refeições, limpeza e outros apoios ao domícilio para os residentes da Barreira.</p>
          </div>
          
          <div className='services__item bg-white p-[30px] rounded-[10px] shadow-custom2 min-h-[288px] flex flex-col items-center text-center justify-center xl:justify-start'>
              <div>
                <i className='ri-health-book-fill text-[50px] text-accent' />
              </div>
              <h3 className='h3 mt-[10px] mb-[10px]'>Cuidados de Saúde</h3>
              <p className='font-light leading-normal max-w-[300px]'>Assistir a população idosa com a toma de medicamentos, gestão de receitas e viagens até a centros de saúde/hospitais.</p>
          </div>
          
          <div className='services__item bg-white p-[30px] rounded-[10px] shadow-custom2 min-h-[250px] flex flex-col items-center text-center justify-center xl:justify-start'>
            <div>
              <i className='ri-team-fill text-[50px] text-accent'/>
            </div>
            <h3 className='h3 mt-[10px] mb-[10px]'>Atividades Culturais, Lazer e Físicas</h3>
            <p className='font-light leading-normal max-w-[300px]'>Dinamizar passeios de bicicleta, vigia de incêncios, etc. para promover a atividade nos idosos e também na população jovem.</p>
          </div>

          

          <div className='services__item bg-white p-[30px] rounded-[10px] shadow-custom2 min-h-[288px] flex flex-col items-center text-center justify-center xl:justify-start'>
            <div>
              <i className='ri-cup-fill text-[50px] text-accent' />
            </div>
            <h3 className='h3 mt-[10px] mb-[10px]'>Café com Convívio</h3>
            <p className='font-light leading-normal max-w-[300px]'>Durante a época quente, fornecer um centro de convívio na Barreira com serviço de café e esplanada.</p>
          </div>

         

        </div>
      </div>
    </section>
  )
}
////////////////////////////////////////////////////////////////////////////////////////////////////
const Organization = () =>{
  const CollapseSection = (props) => {

    let triggerDivClass = "w-full bg-gray-50 h-[64px] flex p-4 px-8 border-b justify-between items-center"
    let triggerDiv =
      <div className={triggerDivClass}>
        <div className='text-primary font-semibold transition-all text-md md:text-lg'>{props.header}</div>
        <div> + </div>
      </div>
    let triggerOpenDiv =
      <div className={triggerDivClass}>
        <div className='text-accent-secondary font-semibold transition-all text-md md:text-lg'>{props.header}</div>
        <div> - </div>
      </div>

    return (
      <Collapsible
        trigger={triggerDiv}
        triggerWhenOpen={triggerOpenDiv}
        transitionTime={200}
        easing={'ease-in-out'}
      >

        <div className='px-8 py-8 text-md font-light'>
          {props.body}
        </div>

      </Collapsible>
    )
  }
  function Person(props){
    var claName = 'person__item bg-white p-[30px] min-h-[230px] flex flex-col items-center text-center justify-center xl:justify-start shadow-custom2 ' + props.flexGrow;
    return(
      <div className={claName}>
        <img className="object-cover  rounded-full w-[64px] h-64px] xl:w-[128px] xl:h-[128px]" src={props.img} />
        <h5 className='h5 mt-4 font-semibold'>{props.name}</h5>
        <span className=''>{props.title}</span>
      </div>
    )
  }
  const Patrimony = () =>{
    const Slide = (props) =>{
      return(
        
        <div className='relative w-full h-full '> 
          <div className='absolute w-full h-full bg-primary'>
            <img src={props.img} className='select-none object-cover brightness-50  self-center  h-full w-full transition-all'></img>
            </div>

          <div className=' z-10 w-full h-full flex flex-col justify-center items-center p-8 pb-12'>
            <h2 className='select-none z-10 text-xl w-fit lg:text-3xl xl:text-4xl font-semibold text-white drop-shadow-md mb-2 pb-2 border-b-2 '>{props.heading}</h2>
            <p className='select-none z-10 text-base text-center md:text-lg lg:text-xl text-white w-[75%] lg:w-[50%] drop-shadow-md'>{props.text}</p>
            </div>
            
          </div>
      
      )
    }
    const swiperRef = useRef();

    return(
      <div className='relative flex h-full w-full overflow-hidden shadow-custom2'>
        <Swiper
          pagination={true}
          autoplay={true}
          loop={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="h-full bg-primary "
          style={{
            "--swiper-pagination-color": "#fff",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "14px",
            "--swiper-pagination-bullet-horizontal-gap": "4px",
            "--swiper-pagination-bottom": "16px"
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}

        >

          <SwiperSlide>
            <Slide img={news2} heading="Centro de Dia" text="Onde preparamos as refeições e cuidados para os cidadãos com a nossa equipa" />
          </SwiperSlide>

          <SwiperSlide>
            <Slide img={news3} heading="Centro de Convívio" text="Onde abrigamos atividades culturais, sociais e de lazer e operamos um serviço de bar no Verão" />
          </SwiperSlide>

          <SwiperSlide>
            <Slide img={news3} heading="Salão de Festas" text="Onde organizamos jantares, almoços e atividades de maior escala" />
          </SwiperSlide>


        </Swiper>
        <div className='absolute z-10 justify-between left-0 w-full h-fit bottom-[8px] md:bottom-[16px] flex flex-row p-2 text-[48px] lg:text-[60px] text-white'>
          <button onClick={() => swiperRef.current?.slidePrev()}>
            <i className="ri-arrow-left-s-fill"></i>
          </button>
          <button onClick={() => swiperRef.current?.slideNext()}>
            <i className="ri-arrow-right-s-fill"></i>
          </button>
        </div>
      </div>
      
    )
  }
  
  return(
    <section name="organizacao"  className='hero bg-grey overflow-hidden py-[128px]'>
      <div className='container mx-auto h-full my-auto' >
        <div className='flex flex-col lg:flex-row items-start justify-between'>
          <div className='hero__text lg:w-[68%] text-center md:text-left'>
            <h1 className="h1 mb-6">Conheça a nossa associação</h1>
            <p className=' mb-[42px] md:max-w-xl'>
             A associação é composta por membros da nossa comunidade e arredores, e tem nos seus pilares o dever moral de solidariedade e apoio para com a população idosa da Barreira.
            </p>
          </div>
          <div className="hero__img hidden lg:flex max-w-[814px]">
            <img src={test} alt="" />
          </div>
        </div> 


        <hr className='hr  rounded-full h-[3px] my-12 w-[50%] mx-auto'></hr>


        <h2 className="h2 text-center xl:text-left mb-8">Informação</h2>
        <div className='mx-auto flex flex-col w-full items-start justify-between shadow-custom2 mb-8'>     
          <CollapseSection 
            header="Razão Social" 
            body={<>Associação de Melhoramentos e Desenvolvimento Social e Cultural da Barreira</>} 
          />
          <CollapseSection 
            header="Atividade"
            body={<>Atividades de apoio social para pessoas idosas, sem alojamento</>} 
          />
          <CollapseSection 
            header="Denominação, Sede, Âmbito e Fins" 
            body={<>A Associação de Melhoramentos e Desenvolvimento Social e Cultural da Barreira, é uma pessoa coletiva, sem finalidade lucrativa, constituída exclusivamente por iniciativa de particulares, com o propósito de dar expressão organizada ao dever moral de justiça e de solidariedade, contribuindo para a efetivação dos direitos sociais dos cidadãos, desde que não sejam administradas pelo Estado ou por outro organismo, com sede no Lugar da Chandria, s/n, 6430-021 Barreira, freguesia da Barreira, concelho de Meda.</>} 
          />
        </div>


        <hr className='hr  rounded-full h-[3px] my-12 w-[50%] mx-auto'></hr>

        {/*
        <h2 className="h2 text-center xl:text-left mb-8">Orgãos Sociais</h2>
        <div className='w-full mx-auto flex flex-row items-center justify-center flex-wrap gap-y-4 flex-1 gap-x-4 mb-8'>
          <Person name="Pedro Dias" img={unc} title="Presidente da Assembleia" flexGrow={'growXXL'}/>
          <Person name="António José Dias" img={jos} title="Presidente da Direção" flexGrow={'growL'} />
          <Person name="José Carlos" img={gra} title="Profissional" flexGrow={'growM'} />      
        </div>
      

        <hr className='hr  rounded-full h-[3px] my-12 w-[50%] mx-auto'></hr>
        

        <h2 className="h2 text-center xl:text-left mb-8">Património</h2>
        <div className=' mx-auto w-full  h-[300px] lg:h-[600px] mb-8'>
          <Patrimony />
        </div>
        */}
      </div>
    </section>

  )

}
////////////////////////////////////////////////////////////////////////////////////////////////////
const Contacts = () => {
  return (
    <div name="contactos" className='container mx-auto my-8 py-8'>
      <h2 className='h2 mb-8'>Contactos</h2>
      
      <div className='w-full flex flex-col justify-between items-start'>
        <div>
          <h4 className='h4'>Contactos Diretos</h4>
          <ul className='m-5' >
           
              <li className='flex items-center p-2'>
                <i className="ri-phone-line text-[28px] mr-4 text-accent-secondary"></i>
                <a href="tel:+351279859498" title="Telefone">
                <span className='flex hover:text-accent-secondary  transition-all'> <span className='hidden md:flex'>[+351]&nbsp;</span> 279 859 498</span>
                </a>
              </li>
            
              <li className='flex items-center p-2 '>
                <i className="ri-mail-send-line text-[28px] mr-4 text-accent-secondary"></i>
              <a href="mailto: ambarreira1998@gmail.com " title="Enviar um e-mail">
                <p className='hidden md:flex hover:text-accent-secondary  transition-all'>ambarreira1998@gmail.com</p>
                <p className='md:hidden'>ambarreira1998 (@gmail.com)</p>
                </a>
              </li>
          </ul>

          <h4 className='h4'>Redes Sociais</h4>
          <ul className='m-5'>

              <li className='flex items-center p-2'>
                <i className="ri-facebook-box-fill text-[32px] mr-4 text-sky-600"></i>
              <a href="https://www.facebook.com/associacoes.barreira" title="AMDSCB - Facebook">
                <p className='hidden md:flex hover:text-sky-600 transition-all'>www.facebook.com/associacoes.barreira</p>
                <p className='md:hidden underline underline-offset-3'>Associações Barreira</p>
                </a>
              </li>

          </ul>


          <h4 className='h4'>Localização</h4>
          <ul className='m-5 hidden md:flex flex-col'>
            <li className='flex items-center p-2'>
              <i className="ri-map-pin-fill text-[28px] mr-4 text-red-600"></i>
              <a href="https://maps.app.goo.gl/MMkuehtanVfHPq4G9" title="AMDSCB - Google Maps">
                <p className='hidden md:flex hover:text-red-600  transition-all'>Lugar da Chandria - Centro de Dia, 6430-021, Barreira</p>
              </a>
            </li>
            <li className='flex items-center p-2'>
              <i className="ri-treasure-map-line text-[28px] mr-4 text-accent-secondary"></i>
              <a href="https://maps.app.goo.gl/tMwSWuREYNebwbzv6" title="Barreira - Google Maps">
                <p className='hidden md:flex hover:text-accent-secondary transition-all'>Barreira, Concelho da Mêda, Distrito da Guarda</p>
              </a>
            </li>
          </ul>
        </div>


        <a className='w-full lg:h-[500px] mt-5 lg:mt-8 overflow-hidden border relative cursor-pointer shadow-custom1' href="https://maps.app.goo.gl/MMkuehtanVfHPq4G9">  
          <img className="z-0 h-full w-full object-none brightness-90 hover:brightness-100 transition-all duration-500" src={map} />
          
          <div className='absolute z-10 top-1 left-1 bg-white shadow-md p-3 px-5'>
            <p className='text-[12px] leading-snug text-primary'>Sede AMDSCB</p>
            <p className='text-[12px] leading-snug text-sky-500 underline'>Ver no Google Maps</p>
          </div>
          
          <div className='hidden md:flex absolute z-10 bottom-1 right-1 min-w-[150px] bg-white shadow-md p-3 px-5'>
            <p className='text-[12px] leading-snug text-primary'>40°54'40.1"N 7°11'12.0"W</p>
          </div>
        </a>

      </div>

    </div>
  )
}
////////////////////////////////////////////////////////////////////////////////////////////////////
const News = () => {
  function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };
  const NewsItem = (props) =>{
    return(
      <div className='services__item bg-white  shadow-custom2 flex flex-col justify-start group w-auto lg:w-[25%]'>
        <div className='flex w-full h-[200px] xl:h-[300px]  justify-center overflow-hidden'>
          <img src={props.image} 
          
          className='object-cover 
          self-center 
          h-full w-full 
          scale-105 ease-in-out group-hover:scale-110 transition-all duration-300' />
        </div>
        <div className='p-4 xl:p-[30px] pt-[10px]'>
          <div className='flex flex-col  pb-[10px] items-start justify-between w-full border-b border-grey'>
            <p className='text-sm text-accent-secondary'>{props.date}</p>
          <h5 className='text-md font-semibold'>{props.headline}</h5>
          
          </div>
          <p className='hidden xl:flex font-light text-sm pt-[10px] leading-snug w-full'>{truncate(props.description, 150)}</p>
          <p className='flex xl:hidden font-light text-sm pt-[10px] leading-snug w-full'>{truncate(props.description, 75)}</p>
        </div>
      </div>
    )
  }
  return (
    <section name="noticias" className='section'>
      <div className='container mx-auto'>
        <h2 className='h2 mb-8 self-center'>Notícias</h2>
        <div className='flex flex-col lg:flex-row gap-4 xl:gap-7 px-0 '>
          {
            news.map((each, index) =>
              <NewsItem 
                key={index} 
                headline={each.headline} 
                image={each.image} 
                date={each.date} 
                description={each.description}
              />
            )
          }
        </div>
      </div>
    </section>

  
  )
}
////////////////////////////////////////////////////////////////////////////////////////////////////
export { Stats, Activities, Organization, Contacts, News }